import { FormattedMessage, Link } from "gatsby-plugin-intl"
import PropTypes from "prop-types"
import React, { useState } from "react"
import Language from "./language"
import BrandSvg from "-!svg-react-loader!../images/brand.svg"

import './header.scss'

const Header = ({ siteTitle }) => {
  const [isCollapsed, setCollapsed] = useState(true)
  const toggleCollapsed = () => {
    setCollapsed(!isCollapsed)
  }

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-white">
      <div className="container">
        <Link className="navbar-brand" to="/">
          <BrandSvg/>
        </Link>

        <button className="navbar-toggler" onClick={toggleCollapsed}>
          <span className="navbar-toggler-icon"/>
        </button>

        <div className={isCollapsed ? "navbar-collapse collapse" : "navbar-collapse"}>
          <ul className="navbar-nav menu">
            <li className="nav-item">
              <Link className="nav-link" to="/#services">
                <FormattedMessage id="menu.services"/>
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/#references">
                <FormattedMessage id="menu.references"/>
              </Link>
            </li>
            {/*<li className="nav-item">*/}
            {/*  <Link className="nav-link" to="/about/">*/}
            {/*    <FormattedMessage id="menu.about"/>*/}
            {/*  </Link>*/}
            {/*</li>*/}
            <li className="nav-item">
              <Link className="nav-link" to="/contact/">
                <FormattedMessage id="menu.contact"/>
              </Link>
            </li>
          </ul>
          <Language/>
        </div>
      </div>
    </nav>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
