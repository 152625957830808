import React, { useState } from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-intl"
import './language.scss'

const languageName = {
  en: { flag: "gb", name: "English" },
  cs: { flag: "cz", name: "Česky" },
  sk: { flag: "sk", name: "Slovensky" },
}

const Language = () => {
  const [isCollapsed, setCollapsed] = useState(true)
  const toggleCollapsed = () => {
    setCollapsed(!isCollapsed)
  }

  return (
    <IntlContextConsumer>
      {({ languages, language: currentLocale }) => (
        <div className="dropdown lang-dropdown">
          <button className="btn btn-link dropdown-toggle" onClick={toggleCollapsed}>
            <span className={"flag-icon flag-icon-" + languageName[currentLocale].flag}/>
            {languageName[currentLocale].name}
          </button>
          <div className={isCollapsed ? "dropdown-menu" : "dropdown-menu show"}>
            {languages.map(language => (
              <button
                key={language}
                onClick={() => changeLocale(language)}
                className="dropdown-item btn btn-link"
              >
                <span className={"flag-icon flag-icon-" + languageName[language].flag}/>
                {languageName[language].name}
              </button>
            ))}
          </div>
        </div>
      )}
    </IntlContextConsumer>
  )
}

export default Language
