import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import "scss/main.scss"

const Layout = ({ children }) => (
  <>
    <Header/>
    <main>{children}</main>
    <footer className="bg-primary text-center p-5 text-white">
      <div className="font-weight-bold">
        Copyright © {new Date().getFullYear()} MLJ solutions a.s.
      </div>
      <div className="small">
        Společnost je zapsána v obchodním rejstříku Městského soudu v Praze, oddíl B, vložka 24756.
      </div>
    </footer>
  </>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
